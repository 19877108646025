import React, { useState } from "react"
import Close from "@material-ui/icons/Close"
import Tune from "@material-ui/icons/Tune"
import {
  Fab,
  Typography,
  Drawer,
  Divider,
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  makeStyles,
} from "@material-ui/core"
import { navigate } from "gatsby"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  fabFilter: {
    bottom: `calc(${theme.mixins.toolbar.minHeight}px + (2 * env(safe-area-inset-bottom)))`,
    right: `env(safe-area-inset-right)`,
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: "fixed",
  },
}))

const Filter = ({ data: { title, cancelTitle, items } }) => {
  const classes = useStyles()
  const [isFilterOpen, setFilterOpen] = useState(false)
  const borderTopRadius = 15

  const toggleDrawer = (open, url) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setFilterOpen(open)
    if (url) {
      navigate(url)
      localStorage.setItem("filterLink", url)
      localStorage.setItem("scrollPositionY", "0")
    }
  }
  const list = (items) => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <HeadlineListItem name={title}>
          <Tune color="secondary" />
        </HeadlineListItem>
      </List>
      <Divider />
      <List>
        {items.map(({ title, url }) => (
          <ListItem button color="secondary" key={title}>
            <ListItemText
              primary={title}
              primaryTypographyProps={{ align: "center", variant: "h5" }}
              onClick={toggleDrawer(false, url)}
            />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <HeadlineListItem name={cancelTitle}>
          <Close color="secondary" />
        </HeadlineListItem>
      </List>
    </div>
  )

  return (
    <>
      <Drawer
        anchor="bottom"
        open={isFilterOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            borderTopLeftRadius: borderTopRadius,
            borderTopRightRadius: borderTopRadius,
            paddingBottom: `calc(env(safe-area-inset-bottom) + env(safe-area-inset-bottom))`,
          },
        }}
      >
        {list(items)}
      </Drawer>
      <Fab
        color="primary"
        aria-label="tune"
        className={classes.fabFilter}
        onClick={toggleDrawer(true)}
      >
        <Tune />
      </Fab>
    </>
  )
}

Filter.propTypes = {
  data: PropTypes.object,
}

const HeadlineListItem = ({ name, children }) => {
  return (
    <ListItem style={{ justifyContent: "center" }}>
      {children !== undefined && (
        <ListItemIcon
          style={{
            minWidth: 36,
          }}
        >
          {children}
        </ListItemIcon>
      )}
      <Typography color="secondary" align="center" variant={"h5"}>
        {name}
      </Typography>
    </ListItem>
  )
}

HeadlineListItem.propTypes = {
  name: PropTypes.string,
  children: PropTypes.object,
}

export default Filter
