import React from "react"
import { Box, makeStyles, useTheme } from "@material-ui/core"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  mainContent: {
    marginBottom: theme.spacing(2),
  },
}))

const CenterContent = ({
  children,
  mt = undefined,
  ml = undefined,
  mr = undefined,
  pt = undefined,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Box
      display="flex"
      className={classes.mainContent}
      style={{
        marginTop: mt === undefined ? theme.spacing(2) : mt,
        marginLeft: ml === undefined ? theme.spacing(2) : ml,
        marginRight: mr === undefined ? theme.spacing(2) : mr,
        paddingTop: pt === undefined ? theme.spacing(2) : pt,
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  )
}
CenterContent.propTypes = {
  children: PropTypes.any,
  ml: PropTypes.number,
  mr: PropTypes.number,
  mt: PropTypes.number,
  pt: PropTypes.number,
}

export default CenterContent
