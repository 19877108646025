import { Box, Grid, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import React from "react"
import moment from "moment-business-time"

const WorkingHours = (props) => {
  const { title, businessHours } = props

  return (
    <>
      <Grid item>
        <Typography
          variant={"h6"}
          color={"textSecondary"}
          align={"center"}
          style={{ fontWeight: "bold" }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item container
            direction={"column"}
            alignContent={"center"}
            alignItems="center"
            wrap={"nowrap"}
      >
        {Object.keys(businessHours).map((day, index) => (
          <Grid item key={index} container xs={9} sm={4} md={4} xl={2} lg={3} style={{ paddingTop: 4, paddingBottom: 4 }}>
            <Grid item xs={6} style={{ textAlign: "left" }}>
              <Typography variant={"body2"} component={"span"}>
                <Box
                  fontWeight={
                    isToday(day) ? "fontWeightBold" : "fontWeightRegular"
                  }
                >
                  {translateDayToGerman(day)}
                </Box>
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ textAlign: "right" }}
              container
              direction={"column"}
            >
              {getWorkingTimeOfDayAsItems(businessHours[day]).map(
                (value, index) => {
                  return (
                    <Grid item key={index}>
                      <Typography variant={"body2"} component={"span"}>
                        <Box
                          fontWeight={
                            isToday(day)
                              ? "fontWeightBold"
                              : "fontWeightRegular"
                          }
                        >
                          {`${value[0]} - ${value[1]}`}
                        </Box>
                      </Typography>
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

const isToday = (day) => {
  return moment().format("dddd").toLowerCase() === day.toLowerCase()
}

const getWorkingTimeOfDayAsItems = (workingTimes) => {
  return toWorkingTimeSegments(workingTimes).map((times) =>
    times.map((time) => time.slice(0, -3))
  )
}

const translateDayToGerman = (day) => {
  switch (day) {
    case "monday":
      return "Montag"
    case "tuesday":
      return "Dienstag"
    case "wednesday":
      return "Mittwoch"
    case "thursday":
      return "Donnerstag"
    case "friday":
      return "Freitag"
    case "saturday":
      return "Samstag"
    case "sunday":
      return "Sonntag"
    default:
      return day
  }
}

const toWorkingTimeSegments = (openingTimes) => {
  return openingTimes.reduce(function (rows, key, index) {
    return (
      (index % 2 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) &&
      rows
    )
  }, [])
}

WorkingHours.propTypes = {
  businessHours: PropTypes.object,
  title: PropTypes.string,
}

export default WorkingHours
