import React from "react"
import { Grid, Link, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

const Email = ({ eMail }) => {
  return (
    <Grid item style={{ padding: 0 }}>
      <Typography variant={"body1"} color={"textSecondary"} component={"span"}>
        <b>E-Mail: </b>
        <Link href={`mailto:${eMail}`} underline={"none"}>
          {eMail}
        </Link>
      </Typography>
    </Grid>
  )
}

export default Email

Email.propTypes = {
  eMail: PropTypes.string,
  isItem: PropTypes.bool,
}
