import React from "react"
import { ShareOutlined as ShareIcon } from "@material-ui/icons"
import IconButton from "@material-ui/core/IconButton"
import PropTypes from "prop-types"
import ShareDialog from "../Dialog/Share"
import { SHARE_TITLE } from "../../utils/constants"
import useDialog from "../../hooks/useDialog"

const SEVERITY = {
  info: "info",
  success: "success",
  error: "error",
}

const Share = (props) => {
  const dialog = useDialog(false)
  const handleSharing = (msg, messageState) => {
    switch (messageState) {
      case SEVERITY.info:
        dialog.onClick()
        break
    }
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="Share"
        onClick={() =>
          onShareClick(props, (msg, messageState) => {
            handleSharing(msg, messageState)
          })
        }
      >
        <ShareIcon color={"secondary"} />
      </IconButton>
      {dialog.open && <ShareDialog {...dialog} {...props} />}
    </>
  )
}

const onShareClick = (props, callback) => {
  const { id, title } = props
  if (navigator.share) {
    navigator
      .share({
        title: `${SHARE_TITLE} - ${title}`,
        url: `${String(window.location)}/${id}`,
      })
      .then(() => {
        console.log("Success sharing")
        callback("Success sharing", SEVERITY.success)
      })
      .catch((error) => {
        console.log("Error sharing", error)
        callback("Error sharing", SEVERITY.error)
      })
  } else {
    callback("Web Share API is not supported in your browser.", SEVERITY.info)
  }
}

Share.propTypes = {
  shareData: PropTypes.object,
  image: PropTypes.string,
}

export default Share
