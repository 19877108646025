import React from "react"
import {
  Toolbar,
  IconButton,
  AppBar,
  makeStyles,
  Grid,
} from "@material-ui/core"
import CallOutlinedIcon from "@material-ui/icons/CallOutlined"
import { LogoComponent } from "../_index"
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: `calc(${theme.spacing(1)}px + env(safe-area-inset-right))`,
  },
}))

const Header = ({ phoneHandler }) => {
  const classes = useStyles()
  return (
    <AppBar position="fixed" elevation={4}>
      <Toolbar disableGutters>
        <Grid container justifyContent={"space-between"}>
          <LogoComponent />
          <IconButton
            color="inherit"
            aria-label="Call"
            className={classes.icon}
            onClick={phoneHandler}
          >
            <CallOutlinedIcon />
          </IconButton>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  phoneHandler: PropTypes.func,
}

export default Header
