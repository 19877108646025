import React, { useEffect, useState } from "react"
import { Grid, Typography, useTheme } from "@material-ui/core"
import { Link } from "gatsby"

const style = {
  noUnderline: {
    color: "inherit",
    textDecoration: "none",
  },
}

const Footer = () => {
  const theme = useTheme()
  const [spacingBottom, setSpacingBottom] = useState(5)

  let containerStyle = {
    textTransform: "uppercase",
    marginBottom: `calc(${theme.spacing(spacingBottom)}px + ${
      theme.mixins.toolbar.minHeight
    }px + env(safe-area-inset-bottom))`,
  }

  useEffect(() => {
    const currentPathName = String(window.location.pathname)
    const isMobileOrTablet = () => {
      return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
    }
    if (currentPathName.includes("gallery") && isMobileOrTablet()) {
      setSpacingBottom(10)
    }
  })

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        direction={"column"}
        alignItems={"center"}
        style={containerStyle}
      >
        <Grid
          item
          container
          justifyContent={"center"}
          direction="row"
          alignItems="center"
        >
          <Grid item style={{ padding: theme.spacing(1) }}>
            <Typography
              variant={"h6"}
              color={"primary"}
              style={{ fontWeight: "bold" }}
            >
              <Link style={style.noUnderline} to={"/imprint"}>
                Impressum
              </Link>
            </Typography>
          </Grid>
          <Grid item style={{ padding: theme.spacing(1) }}>
            <Typography
              variant={"h6"}
              color={"primary"}
              style={{ fontWeight: "bold" }}
            >
              <Link style={style.noUnderline} to={"/privacy"}>
                Datenschutz
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant={"h6"} color={"primary"}>
            © 2020 Goldschmiede-Atelier Häring
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default Footer
