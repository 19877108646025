import React from "react"
import { Grid, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

const Address = ({ address = {} }) => {
  return (
    <Grid item>
      <Grid item>
        <Typography
          variant={"h6"}
          color={"textSecondary"}
          align={"center"}
          style={{ fontWeight: "bold" }}
        >
          {address.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={"body1"} color={"textSecondary"} align={"center"}>
          {`${address.street} ${address.streetNo}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={"body1"} color={"textSecondary"} align={"center"}>
          {`${address.postalCode} ${address.city}`}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Address

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    streetNo: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
  }),
  isItem: PropTypes.bool,
}
