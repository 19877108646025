import React from "react"
import * as PropTypes from "prop-types"
import PaginationDot from "./Dots"

const Pagination = ({ onChangeIndex, index, dots }) => {
  const handleClick = (event, index) => {
    onChangeIndex(index)
  }
  const children = []
  for (let i = 0; i < dots; i += 1) {
    children.push(
      <PaginationDot
        key={i}
        index={i}
        active={i === index}
        onClick={handleClick}
      />
    )
  }
  return dots > 1 ? <div>{children}</div> : null
}

export default Pagination

Pagination.propTypes = {
  dots: PropTypes.number,
  index: PropTypes.number,
  onChangeIndex: PropTypes.func,
}
