import { useState } from "react"
import { parsePhoneNumberFromString } from "libphonenumber-js"
import { graphql, useStaticQuery } from "gatsby"

export default (initValue) => {
  const {
    contentfulContact: { phoneNo },
  } = useStaticQuery(
    graphql`
      query CONTENTFUL_CONTACT {
        contentfulContact {
          phoneNo: telephone
        }
      }
    `
  )
  const [open, setOpen] = useState(initValue)

  const handleClick = () => {
    setOpen(!open)
  }
  const phoneNumber = parsePhoneNumberFromString(phoneNo, "DE")

  return { open, onClick: handleClick, phoneNumber }
}
