export const THEME = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      light: "rgba(149,152,154,0.3)",
      main: "rgb(110,111,113)",
      dark: "rgba(98, 99, 100, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(110, 31, 115, 0.59)",
      main: "rgb(110,31,115)",
      dark: "rgba(111, 16, 117, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgb(110,111,113)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  divider: "rgba(149,152,154,0.8)",
  dotBackground: "rgb(110,111,113)",
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "1.8rem",
    },
    h6: {
      fontSize: "0.8rem",
    },
  },
  overrides: {
    MuiBottomNavigationAction: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
        "&$selected": {
          color: "rgb(110,31,115)",
        },
      },
      selected: {},
    },
  },
}

export const PHONE_DIALOG_TITLE = `Beratung`

export const SHARE_DIALOG_TITLE = `Teilen`
export const SHARE_TITLE = `Goldschmiede - Atelier Häring`
export const SHARE_COPY = `Kopieren`
export const ADDRESS_COPY = `Adresse kopieren`
export const SNACKBAR_SUCCESS_COPY = `Adresse erfolgreich kopiert`
export const WEEK_DAYS_DE = `Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag`
export const WEEK_DAYS_EN = `Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday`

export const GALLERY_ITEM_CATEGORY_TITLE = `Kategorie`
