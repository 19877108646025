import React from "react"
import { Header, BottomBar, PhoneDialog } from "../_index"
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  useMediaQuery,
} from "@material-ui/core"
import { THEME } from "../../utils/constants"
import { ThemeProvider } from "@material-ui/styles"
import PropTypes from "prop-types"
import useDialog from "../../hooks/useDialog"
import Footer from "../Footer"

const Layout = ({ children }) => {
  const dialog = useDialog(false)
  const theme = responsiveFontSizes(createTheme(THEME))

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header phoneHandler={dialog.onClick} />
      {dialog.open && <PhoneDialog {...dialog} />}
      <main style={{ marginTop: getMinHeight(theme) }}>
        {children}
        <Footer />
      </main>
      <BottomBar />
    </ThemeProvider>
  )
}

const getMinHeight = (theme) => {
  const matchSmLand = useMediaQuery(
    "(min-width:0px) and (orientation: landscape)"
  )
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  let marginTop = theme.mixins.toolbar.minHeight
  if (matches) {
    marginTop = theme.mixins.toolbar["@media (min-width:600px)"].minHeight
  } else if (matchSmLand) {
    marginTop =
      theme.mixins.toolbar[
        "@media (min-width:0px) and (orientation: landscape)"
      ].minHeight
  }
  return marginTop
}

Layout.propTypes = {
  selectedMenuItemIndex: PropTypes.number,
  children: PropTypes.any,
}

export default Layout
