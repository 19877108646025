import React from "react"
import * as PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    height: 18,
    width: 18,
    cursor: "pointer",
    border: 0,
    background: "none",
    padding: 0,
  },
  dot: {
    backgroundColor: theme.dotBackground,
    height: 12,
    width: 12,
    borderRadius: 6,
    margin: 3,
    "&.active": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))

const PaginationDot = ({ active, onClick, index }) => {
  const classes = useStyles()
  const handleClick = event => {
    onClick(event, index)
  }
  const styleDot = active ? `${classes.dot} active` : classes.dot

  return (
    <button type="button" className={classes.root} onClick={handleClick}>
      <div className={styleDot} />
    </button>
  )
}

export default PaginationDot

PaginationDot.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
}
