import React from "react"
import { withStyles } from "@material-ui/core/styles"
import {
  Typography,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import {
  SHARE_COPY,
  SHARE_DIALOG_TITLE,
  SHARE_TITLE,
} from "../../../utils/constants"
import PropTypes from "prop-types"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"
import { CopyToClipboard } from "react-copy-to-clipboard"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  copyBox: {
    background: theme.palette.primary.light,
    borderRadius: 2,
    "& input": {
      background: "rgba(0, 0, 0, 0)",
      fontSize: "inherit",
      color: "inherit",
      border: "none",
      overflow: "hidden",
      width: "100%",
    },
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" align={"center"} color="secondary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const ShareLinks = withStyles(styles)(({ classes, id, title, shareImage }) => {
  const shareConfig = {
    url: `${String(window.location)}/${id}`,
    media: shareImage,
    description: title,
    title: `${SHARE_TITLE} - ${title}`,
    subject: `${SHARE_TITLE} - ${title}`,
  }

  return (
    <>
      <Grid
        container
        justify={"space-evenly"}
        spacing={4}
        style={{ padding: "inherit" }}
      >
        <Grid item>
          <WhatsappShareButton {...shareConfig}>
            <WhatsappIcon size={60} round />
            <Typography variant={"inherit"} component={"div"}>
              Whatsapp
            </Typography>
          </WhatsappShareButton>
        </Grid>
        <Grid item>
          <FacebookShareButton {...shareConfig}>
            <FacebookIcon size={60} round />
            <Typography variant={"inherit"} component={"div"}>
              Facebook
            </Typography>
          </FacebookShareButton>
        </Grid>
        <Grid item>
          <PinterestShareButton {...shareConfig}>
            <PinterestIcon size={60} round />
            <Typography variant={"inherit"} component={"div"}>
              Pinterest
            </Typography>
          </PinterestShareButton>
        </Grid>
        <Grid item>
          <EmailShareButton {...shareConfig}>
            <EmailIcon size={60} round />
            <Typography variant={"inherit"} component={"div"}>
              E-Mail
            </Typography>
          </EmailShareButton>
        </Grid>
        <Grid item container>
          <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            spacing={2}
            className={classes.copyBox}
          >
            <Grid item xs>
              <input readOnly value={shareConfig.url} />
            </Grid>
            <Grid item>
              <CopyToClipboard text={shareConfig.url}>
                <Button>
                  <Typography variant={"body2"}>{SHARE_COPY}</Typography>
                </Button>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
})

const ShareDialog = (props) => {
  return (
    <>
      <Dialog
        onClose={props.onClick}
        aria-labelledby="share-dialog-title"
        open={props.open}
        fullWidth={true}
      >
        <DialogTitle id="share-dialog-title" onClose={props.onClick}>
          {SHARE_DIALOG_TITLE}
        </DialogTitle>
        <DialogContent dividers>
          <ShareLinks {...props} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ShareDialog

ShareDialog.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  category: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
}
