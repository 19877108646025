import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"

const textColor = "textSecondary"

const SubAndHeadline = ({ title, subtitle }) => {
  return (
    <>
      <Grid item>
        <Box borderBottom={2} color="secondary.main" pb={2} component={"span"}>
          <Typography variant="h1" color={textColor} component={"span"}>
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle1"
          color={textColor}
          style={{ lineHeight: "1.5rem" }}
        >
          {subtitle}
        </Typography>
      </Grid>
    </>
  )
}

SubAndHeadline.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
}

export default SubAndHeadline
