import React from "react"
import Dialog from "@material-ui/core/Dialog"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import MuiDialogContent from "@material-ui/core/DialogContent"
import PropTypes from "prop-types"
import { Link } from "@material-ui/core"
import { PHONE_DIALOG_TITLE } from "../../../utils/constants"
import CallOutlined from "@material-ui/icons/CallOutlined"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
// TODO: Add traffic lights depends on openHours
// import useOpenHours from "../../../hooks/useOpenHours"

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent)

const PhoneDialog = (props) => {
  const { open, onClick: onClickHandler, phoneNumber } = props
  // TODO: Add traffic lights depends on openHours
  // console.log(useOpenHours().isWorking)
  return (
    <>
      <Dialog
        onClose={onClickHandler}
        aria-labelledby="phone-dialog-title"
        open={open}
      >
        <Box display="flex" justifyContent="flex-end">
          {onClickHandler ? (
            <IconButton
              aria-label="close"
              style={{ float: "right" }}
              onClick={onClickHandler}
            >
              <CloseIcon color={"secondary"} fontSize={"large"} />
            </IconButton>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography component="div" variant={"h5"}>
            <Box
              style={{ textTransform: "uppercase" }}
              borderBottom={2}
              color="secondary.main"
            >
              {PHONE_DIALOG_TITLE}
            </Box>
          </Typography>
        </Box>
        <DialogContent>
          <Grid container alignItems={"center"}>
            <Grid item>
              <Typography variant={"h5"}>{phoneNumber.number}</Typography>
            </Grid>
            <Grid item>
              <Link href={`tel:${phoneNumber.number}`} underline={"none"}>
                <IconButton
                  color="inherit"
                  aria-label="Call"
                  aria-controls="menu-appBar"
                  aria-haspopup="true"
                >
                  <CallOutlined />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

PhoneDialog.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func,
  phoneNumber: PropTypes.object,
}

export default PhoneDialog
