import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../../hooks/useSiteMetadata"
import PropTypes from "prop-types"

const SiteMetadata = (props) => {
  const { title, description } = props
  const { title: siteTitle } = useSiteMetadata()
  const locale = "de"
  let pathname = ""
  let location = ""
  const href = `${location}${pathname}`

  useEffect(() => {
    pathname = String(window.location.pathname)
    location = String(window.location.origin)
  })

  return (
    <Helmet
      defer={false}
      defaultTitle={siteTitle}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={locale} dir="ltr" />
      <link rel="canonical" href={href} />
      <meta name="docsearch:version" content="2.0" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
      />

      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={siteTitle} />
      <meta name="description" content={description} />
      <title itemProp="name" lang="de">
        {title}
      </title>
    </Helmet>
  )
}

SiteMetadata.propTypes = {
  description: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
}

export default SiteMetadata
