import React, { useState } from "react"
import { useMediaQuery, useTheme } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import * as PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import LogoOutlineComponent from "../Logo/Item/LogoOutlineComponent"
import SwipeableViews from "react-swipeable-views"
import Pagination from "../Pagination"
import GatsbyImage from "gatsby-image"
import Share from "../Share"
import { GALLERY_ITEM_CATEGORY_TITLE } from "../../utils/constants"

const GalleryItem = (props) => {
  const {
    id,
    images,
    category = "Damenringe",
    title = "Description",
    hashTags = "Hashtags",
  } = props

  const [index, setIndex] = useState(0)
  const handleChangeIndex = (index) => {
    setIndex(index)
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  const paddingStyle = {
    paddingLeft: matches ? theme.spacing(1) : theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignItems: "center",
  }
  const extendedHeaderStyle = {
    ...paddingStyle,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
  const extendedContentStyle = {
    ...paddingStyle,
    paddingRight: theme.spacing(2),
    minWidth: "50vw",
    titleAndDescriptionStyle: {
      wordBreak: "break-word",
      paddingRight: theme.spacing(2),
    },
  }
  const shareData = {
    id,
    title,
    category,
  }
  const sliderWrapper = {
    enableMouseEvents: true,
    onChangeIndex: handleChangeIndex,
    index,
  }
  const captionWrapper = {
    ...sliderWrapper,
    shareData,
    images,
  }

  return (
    <Grid container item>
      {/* header */}
      <ItemHeader style={extendedHeaderStyle} category={category} />
      {/* image */}
      <Grid container item>
        <SwipeableViews {...sliderWrapper} style={{ flex: 1 }}>
          {images.map(({ fluid, title }, index) => (
            <GatsbyImage key={index} fluid={fluid} title={title} />
          ))}
        </SwipeableViews>
      </Grid>
      {/* caption */}
      <ItemCaption style={paddingStyle} {...captionWrapper} />
      {/* article title */}
      <ItemDescription style={extendedContentStyle} title={title} />
      {/* hashtags */}
      <ItemHashTags style={extendedContentStyle} hashTags={hashTags} />
    </Grid>
  )
}

GalleryItem.propTypes = {
  id: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  hashTags: PropTypes.array,
  images: PropTypes.array,
}

// region ItemHeader

const ItemHeader = ({ style, category }) => {
  return (
    <Grid container item style={style}>
      <Typography variant={"body1"} component={"span"}>
        {GALLERY_ITEM_CATEGORY_TITLE}
        <Typography variant={"inherit"} component={"span"} color={"secondary"}>
          {` • ${category}`}
        </Typography>
      </Typography>
    </Grid>
  )
}

ItemHeader.propTypes = {
  category: PropTypes.string,
  style: PropTypes.object,
}

// endregion

// region ItemCaption

const ItemCaption = ({ style, shareData, index, onChangeIndex, images }) => {
  const dots = images.length
  const shareImage = images[0].fluid.src
  return (
    <Grid container item justifyContent={"space-between"} style={style}>
      <Grid item>
        <LogoOutlineComponent />
      </Grid>
      <Grid item>
        <Pagination dots={dots} index={index} onChangeIndex={onChangeIndex} />
      </Grid>
      <Grid item>
        <Share {...{ ...shareData, shareImage }} />
      </Grid>
    </Grid>
  )
}

ItemCaption.propTypes = {
  images: PropTypes.array,
  index: PropTypes.number,
  onChangeIndex: PropTypes.func,
  shareData: PropTypes.object,
  style: PropTypes.object,
}

// endregion

// region ItemDescription

const ItemDescription = ({ style, title }) => {
  return (
    <Grid container item style={style}>
      <Typography
        variant={"h6"}
        style={style.titleAndDescriptionStyle}
        gutterBottom
      >
        {title}
      </Typography>
    </Grid>
  )
}

ItemDescription.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
}

// endregion

// region ItemHashTags

const ItemHashTags = ({ style, hashTags }) => {
  return (
    <Grid container item style={style}>
      <Typography
        variant={"body1"}
        style={style.titleAndDescriptionStyle}
        gutterBottom
      >
        {hashTags.map((hashTag) => `#${hashTag}`)}
      </Typography>
    </Grid>
  )
}

ItemHashTags.propTypes = {
  hashTags: PropTypes.array,
  style: PropTypes.object,
}

// endregion

export default GalleryItem
