import React, { useEffect, useState } from "react"
import {
  BottomNavigation,
  BottomNavigationAction,
  SvgIcon,
  makeStyles,
} from "@material-ui/core"
import PersonPinCircleOutlined from "@material-ui/icons/PersonPinCircleOutlined"
import InfoOutlined from "@material-ui/icons/InfoOutlined"
import { navigate } from "gatsby"

const useStyles = makeStyles((theme) => ({
  bottomBar: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    borderTop: "solid",
    borderColor: theme.divider,
    borderWidth: 1,
    height: `calc(${theme.mixins.toolbar.minHeight}px + (2 * env(safe-area-inset-bottom)))`,
    alignItems: "start",
  },
}))

const galleryIcon = (
  <SvgIcon>
    <path d="M16.121,0H3.9a.4.4,0,0,0-.347.211L.079,5.961A.517.517,0,0,0,.1,6.515L9.687,19.827a.388.388,0,0,0,.646,0L19.916,6.515a.517.517,0,0,0,.024-.555L16.472.211A.409.409,0,0,0,16.121,0Zm-.67,1.875,2.2,3.75H15.274l-1.8-3.75h1.972Zm-7.018,0h3.149l1.8,3.75H6.642Zm-3.865,0H6.541l-1.8,3.75H2.371ZM3.076,7.5H4.86l2.372,6.25Zm3.573,0h6.722L10.01,17Zm6.139,6.25L15.156,7.5h1.785Z" />
  </SvgIcon>
)
const getIndexForPathName = () => {
  if (String(window.location.pathname).includes("gallery")) {
    return 1
  } else {
    switch (String(window.location.pathname)) {
      case "/contact":
      case "/contact/":
        return 2
      default:
        return 0
    }
  }
}
const BottomBar = () => {
  const classes = useStyles()
  const [link, setLink] = useState("/gallery/damenringe")
  const [indexItem, setIndexItem] = useState(0)

  useEffect(() => {
    setIndexItem(getIndexForPathName())
    const filterLink = localStorage.getItem("filterLink")
    if (filterLink) setLink(filterLink)
  })

  const onBottomItemClickedHandler = (event, itemIndex) => {
    switch (itemIndex) {
      case 1:
        navigate(link)
        break
      case 2:
        navigate("/contact")
        break
      default:
        navigate("/")
        break
    }
  }

  return (
    <BottomNavigation
      value={indexItem}
      onChange={onBottomItemClickedHandler}
      showLabels
      className={classes.bottomBar}
    >
      <BottomNavigationAction
        label="Info"
        icon={<InfoOutlined />}
        className={classes.bottomNavigationAction}
      />
      <BottomNavigationAction
        label="Galerie"
        icon={galleryIcon}
        className={classes.bottomNavigationAction}
      />
      <BottomNavigationAction
        label="Kontakt"
        icon={<PersonPinCircleOutlined />}
        className={classes.bottomNavigationAction}
      />
    </BottomNavigation>
  )
}

export default BottomBar
