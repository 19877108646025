import Snackbar from "@material-ui/core/Snackbar"
import { Typography } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import PropTypes from "prop-types"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => ({
  snackbar: {
    bottom: 90,
  },
}))

const SnackBarComponent = (props) => {
  const { isOpen, handleOpen, text } = props

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    handleOpen(false)
  }

  const classes = useStyles()
  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      message={<Typography component={"div"}>{text}</Typography>}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  )
}

SnackBarComponent.propTypes = {
  handleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
}

export default SnackBarComponent
