import React from "react"
import { Grid, Link, Typography } from "@material-ui/core"
import PropTypes from "prop-types"

const LabelNumber = ({
  label,
  number,
  pt = undefined,
  pb = undefined,
  callable = false,
}) => {
  return (
    <>
      <Grid item style={{ paddingTop: pt, paddingBottom: pb }}>
        <Grid item xs={12}>
          <Typography
            variant={"body1"}
            color={"textSecondary"}
            component={"span"}
          >
            <b>{label}</b>
            {callable ? (
              <Link href={`tel:${number}`} underline={"none"}>
                {number}
              </Link>
            ) : (
              number
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default LabelNumber

LabelNumber.propTypes = {
  callable: PropTypes.bool,
  isItem: PropTypes.bool,
  label: PropTypes.string,
  number: PropTypes.string,
  pb: PropTypes.number,
  pt: PropTypes.number,
}
